:root {
  --gridList-column: 250px;
  --gridList-rows: 250px;
  --gridList-gap: 6px;
}

.ListOfGifs {
  display: grid;
  min-height: 100vh;
  grid-template-columns: repeat(auto-fit, minmax(var(--gridList-column), 1fr));
  grid-auto-rows: var(--gridList-rows);
  grid-template-rows: masonry;
  grid-auto-flow: row dense;
  grid-gap: var(--gridList-gap, 6px);
  gap: var(--gridList-gap, 6px);
  align-items: center;
}

@media screen and (min-width: 37.5rem) {
  :root {
    --gridList-column: 180px;
    --gridList-rows: 200px;
  }
}

@media screen and (min-width: 60rem) {
  :root {
    --gridList-column: 300px;
    --gridList-rows: 210px;
  }
}

h4 {
  margin: 1em auto;
  color: #ceaefff9;
  text-align: center;
}
