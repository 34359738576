@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Josefin Sans", sans-serif;
  overflow-x: hidden;
  min-width: 375px;
  min-height: 100vh;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 1) 0%,
    rgba(54, 9, 121, 0.9752275910364145) 50%,
    rgba(0, 0, 0, 1) 100%
  );
  font-size: 18px;
}

.container {
  background-color: #000000a8;
  border-radius: 10px;
  border: 2px solid #250653c7;
  width: 80%;
  margin: 1em auto;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
}

h3 {
  color: #ceaefff9;
}

hr {
  margin: 0.5em auto;
  width: 100%;
  display: block;
  border-color: #360979f9;
  border-radius: 1px;
  border-style: solid;
}

.error {
  color: #ceaefff9;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 25pt;
}

/* The emerging W3C standard
   that is currently Firefox-only */
* {
  scrollbar-width: thin;
  scrollbar-color: black #360979f9;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar-track {
  background: black;
}
*::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 2px;
  border: 1px solid #360979f9;
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  padding: 1em 3em;
  min-height: 8vh;
  flex-wrap: wrap;
}

.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
}

.logo h1 {
  color: #ceaefff9;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 1.1em;
  text-align: left;
}

.logo a {
  text-decoration: none;
  color: #ceaefff9;
  width: 100%;
}

.burger {
  cursor: pointer;
  width: 20px;
  height: 20px;
  color: #ceaefff9;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  -moz-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.toggle {
  -webkit-animation: burgerChange 0.3s ease;
          animation: burgerChange 0.3s ease;
}

.nav-links {
  display: none;
  opacity: 0;
  -webkit-animation: navLinkFade 0.3s ease;
          animation: navLinkFade 0.3s ease;
}

.nav-active {
  display: block;
  opacity: 1;
  padding-top: 1em;
  position: absolute;
  width: 100%;
  height: auto;
  background: rgba(0, 0, 0, 0.97);
  z-index: 10;
  border-top: 1px solid #360979f9;
  top: 3em;
}

.nav-links li {
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 1.5em 0;
  width: 80%;
  margin: auto;
  border-bottom: 1px solid #360979f9;
}

.nav-links li:last-child {
  border: none;
}

.nav-links a {
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 3px;
  font-weight: bold;
  font-size: 0.7em;
  color: #9b5bfbf9;
}

.nav-links a:hover {
  color: rgba(62, 22, 122, 0.975);
}

.nav-links a.active {
  color: #ceaefff9;
}

@-webkit-keyframes navLinkFade {
  from {
    opacity: 0;
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@-webkit-keyframes burgerChange {
  from {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
  to {
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
  }
}

@keyframes burgerChange {
  from {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }
  to {
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
  }
}

@media screen and (min-width: 768px) {
  body {
    overflow-x: hidden;
  }
  .burger {
    display: none;
  }
  .nav {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    padding: 1em 3em;
    min-height: 8vh;
  }
  .nav-links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    opacity: 1;
    -webkit-animation: none;
            animation: none;
  }

  .nav-active {
    background: rgba(0, 0, 0, 0.97);
    z-index: 1;
    border-top: none;
    top: 0;
  }
  .logo {
    width: 20%;
    justify-content: flex-start;
  }
  .nav-links li {
    list-style: none;
    width: 80%;
    border-bottom: none;
    margin: 0 1em;
  }
}

.search {
  display: flex;
  margin: auto;
  width: 90%;
}

.searchTerm {
  width: 100%;
  padding: 5px 5px 5px 10px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #000000;
  background-color: #bdbdbd;
  border: 1px solid #bdbdbd;
  border-right: none;
  font-size: 0.7em;
}

.searchButton {
  width: 40px;
  text-align: center;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  /* font-size: 20px; */
  background-color: rgba(54, 9, 121, 0.9752275910364145);
  border: 1px solid rgba(54, 9, 121, 0.9752275910364145);
  color: rgba(212, 187, 250, 0.975);
}

/*Resize the wrap to see the search bar change!*/
.searchBar {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  min-height: 5vh;
  background-color: #000000;
  padding: 0 1em;
  padding-bottom: 1em;
  width: 100%;
}

.search input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg)
    no-repeat 50% 50%;
  background-size: contain;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
}

.search input[type="search"]:focus::-webkit-search-cancel-button {
  opacity: 0.3;
  pointer-events: all;
}

.search input[type="search"].dark::-webkit-search-cancel-button {
  -webkit-filter: invert(1);
          filter: invert(1);
}

.user-profile {
  display: none;
}

@media screen and (min-width: 786px) {
  .searchBar {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    padding: 0 3em;
    padding-bottom: 1em;
    width: 100%;
  }

  .search {
    margin: auto;
    width: 50%;
  }

  .user-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: auto;
  }

  .avatar-mini {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .avatar-mini img {
    display: block;
    width: 100%;
  }

  .username {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    letter-spacing: 2px;
    text-align: center;
    margin-left: 1em;
    font-size: 0.8em;
  }
}

.smallLoader {
  background: rgba(54, 9, 121, 0.8);
  border: 0;
  border-radius: 100px;
  width: 40px;
  height: 40px;
}

.smallLoader img {
  width: 20px;
  fill: #ceaefff9;
}

.giftter-Fav {
  background: rgba(54, 9, 121, 0.7);
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.giftter-Fav:hover {
  background: rgb(44, 8, 99);
}

.giftter-Fav .icon {
  width: 30px;
  color: rgb(224, 224, 224);
}

.giftter {
  --gifShadow-size: var(--gridList-gap, 6px);
  margin-bottom: 0.5em;
  position: relative;
}

.giftter-buttons {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}

.giftter-link {
  color: #000;
  display: block;
  text-decoration: none;
  position: relative;
  width: 100%;
}

.giftter h4 {
  background: rgba(0, 0, 0, 0.3);
  bottom: 0;
  font-size: 0.7em;
  color: #d9bfff;
  margin: 0;
  position: absolute;
  padding: 0.5em 0.5em;
  width: 100%;
  line-height: 1.5em;
}

.giftter img {
  width: 100%;
  object-fit: contain;
  vertical-align: top;
}

i {
  font-size: 80px;
}

.giftter {
  margin-bottom: 0;
}

.giftter,
.giftter-link {
  height: 100%;
}
.giftter-link img {
  height: inherit;
  object-fit: cover;
}

.giftter:nth-child(5n + 1) img {
  background-color: var(--brand-color_1);
}
.giftter:nth-child(5n + 2) img {
  background-color: var(--brand-color_2);
}
.Gif:nth-child(5n + 3) img {
  background-color: var(--brand-color_3);
}
.giftter:nth-child(5n + 4) img {
  background-color: var(--brand-color_4);
}
.giftter:nth-child(5n + 5) img {
  background-color: var(--brand-color_5);
}

.giftter:hover:nth-child(5n + 1) {
  box-shadow: 0 0 0 var(--gifShadow-size) var(--brand-color_1);
}
.giftter:hover:nth-child(5n + 2) {
  box-shadow: 0 0 0 var(--gifShadow-size) var(--brand-color_2);
}
.giftter:hover:nth-child(5n + 3) {
  box-shadow: 0 0 0 var(--gifShadow-size) var(--brand-color_3);
}
.giftter:hover:nth-child(5n + 4) {
  box-shadow: 0 0 0 var(--gifShadow-size) var(--brand-color_4);
}
.giftter:hover:nth-child(5n + 5) {
  box-shadow: 0 0 0 var(--gifShadow-size) var(--brand-color_5);
}

.giftter:hover:nth-child(5n + 1) h4 {
  background-color: var(--brand-color_1);
  color: var(--theme-body-bg);
}
.giftter:hover:nth-child(5n + 2) h4 {
  background-color: var(--brand-color_2);
  color: var(--theme-body-bg);
}
.giftter:hover:nth-child(5n + 3) h4 {
  background-color: var(--brand-color_3);
  color: white;
}
.giftter:hover:nth-child(5n + 4) h4 {
  background-color: var(--brand-color_4);
  color: white;
}
.giftter:hover:nth-child(5n + 5) h4 {
  background-color: var(--brand-color_5);
  color: var(--theme-body-bg);
}

.giftter:nth-child(11n + 1) {
  grid-column: span 2;
  grid-row: span 2;
}

.giftter:nth-child(8n + 1) {
  grid-column-end: span 2;
  grid-row-end: span 2;
}

.giftter:nth-child(10n + 3) {
  grid-column: span 2;
  grid-row: span 1;
}

@media screen and (max-width: 45rem) {
  .giftter:nth-child(11n + 1),
  .giftter:nth-child(8n + 1),
  .giftter:nth-child(10n + 3) {
    grid-column: span 1;
    grid-row: span 1;
  }
}

.loader {
  margin: auto;
  text-align: center;
}

.sharedcontent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-top: 1em;
}

.item {
  display: flex;
  padding: 0.1em;
  align-items: center;
}

span {
  color: #ceaefff9;
  margin-right: 10px;
}

.loginForm {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loginForm .group {
  display: flex;
  padding: 1em;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.loginForm label {
  color: #ceaefff9;
  text-transform: uppercase;
  font-size: 0.6em;
  letter-spacing: 0.1em;
  margin-bottom: 1em;
}

.loginForm input {
  padding: 0.5em 2.5em 0.5em 1em;
  color: #000000;
  background-color: #bdbdbd;
  border: #bdbdbd;
  border-radius: 5px;
}

.loginForm input:focus {
  outline: none;
}

.loginForm button {
  width: 215px;
  padding: 0.6em 0;
  background-color: rgba(54, 9, 121, 0.9752275910364145);
  border: rgba(54, 9, 121, 0.9752275910364145);
  border-radius: 3px;
  color: rgba(212, 187, 250, 0.975);
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 0.2em;
  font-size: 0.6em;
  font-weight: bold;
}

.loginForm button:hover {
  background-color: rgba(62, 22, 122, 0.975);
}

.loginForm ul {
  background-color: #360979a8;
  border-radius: 5px;
  border: 1px solid #ceaefff9;
  padding: 0.5em 2em;
  font-size: 0.8em;
  font-weight: normal;
}

.loginForm ul > li {
  color: #ceaefff9;
  padding: 0.5em;
}

.loginForm strong {
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: underline;
  margin-right: 0.5em;
  font-size: 0.9em;
  letter-spacing: 1px;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .loginForm {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 5em;
  }
  .loginForm .group {
    display: flex;
    padding: 1em 1em;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .loginForm label {
    width: 100%;
    font-size: 0.6em;
    margin: 0;
  }
  .loginForm input {
    width: 100%;
  }
  .loginForm .group {
    width: 100%;
  }
  .loginForm ul {
    width: 100%;
    font-size: 0.7em;
  }
  .loginForm ul {
    line-height: 1.5em;
  }
  .loginForm ul > li {
    padding: 0.3em;
  }
}

@media (min-width: 1440px) {
  .loginForm {
    padding: 0 12em;
  }
}

.registerForm {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.registerForm .group {
  display: flex;
  padding: 1em;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.registerForm label {
  color: #ceaefff9;
  text-transform: uppercase;
  font-size: 0.6em;
  letter-spacing: 0.1em;
  margin-bottom: 1em;
}

.registerForm input {
  padding: 0.5em 2.5em 0.5em 1em;
  color: #000000;
  background-color: #bdbdbd;
  border: #bdbdbd;
  border-radius: 5px;
}

.registerForm input:focus {
  outline: none;
}

.registerForm button {
  width: 215px;
  padding: 0.6em 0;
  background-color: rgba(54, 9, 121, 0.9752275910364145);
  border: rgba(54, 9, 121, 0.9752275910364145);
  border-radius: 3px;
  color: rgba(212, 187, 250, 0.975);
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 0.2em;
  font-size: 0.6em;
  font-weight: bold;
}

.registerForm button:hover {
  background-color: rgba(62, 22, 122, 0.975);
}

.registerForm ul {
  background-color: #360979a8;
  border-radius: 5px;
  border: 1px solid #ceaefff9;
  padding: 0.5em 2em;
  font-size: 0.8em;
  font-weight: normal;
}

.registerForm ul > li {
  color: #ceaefff9;
  padding: 0.5em;
}

.registerForm strong {
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: underline;
  margin-right: 0.5em;
  font-size: 0.9em;
  letter-spacing: 1px;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .registerForm {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 5em;
  }
  .registerForm .group {
    display: flex;
    padding: 1em 1em;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .registerForm label {
    width: 100%;
    font-size: 0.6em;
    margin: 0;
  }
  .registerForm input {
    width: 100%;
  }
  .registerForm .group {
    width: 100%;
  }
  .registerForm ul {
    width: 100%;
    font-size: 0.7em;
  }
  .registerForm ul {
    line-height: 1.5em;
  }
  .registerForm ul > li {
    padding: 0.3em;
  }
}

@media (min-width: 1440px) {
  .registerForm {
    padding: 0 12em;
  }
}

:root {
  --gridList-column: 250px;
  --gridList-rows: 250px;
  --gridList-gap: 6px;
}

.ListOfGifs {
  display: grid;
  min-height: 100vh;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-template-columns: repeat(auto-fit, minmax(var(--gridList-column), 1fr));
  grid-auto-rows: 250px;
  grid-auto-rows: var(--gridList-rows);
  grid-template-rows: masonry;
  grid-auto-flow: row dense;
  grid-gap: 6px;
  grid-gap: var(--gridList-gap, 6px);
  grid-gap: 6px;
  gap: 6px;
  grid-gap: var(--gridList-gap, 6px);
  gap: var(--gridList-gap, 6px);
  align-items: center;
}

@media screen and (min-width: 37.5rem) {
  :root {
    --gridList-column: 180px;
    --gridList-rows: 200px;
  }
}

@media screen and (min-width: 60rem) {
  :root {
    --gridList-column: 300px;
    --gridList-rows: 210px;
  }
}

h4 {
  margin: 1em auto;
  color: #ceaefff9;
  text-align: center;
}

.profileForm {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.profileForm .group {
  display: flex;
  padding: 1em;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.profileForm label {
  color: #ceaefff9;
  text-transform: uppercase;
  font-size: 0.6em;
  letter-spacing: 0.1em;
  margin-bottom: 1em;
}

.profileForm input {
  padding: 0.5em 2.5em 0.5em 1em;
  color: #000000;
  background-color: #bdbdbd;
  border: #bdbdbd;
  border-radius: 5px;
}

.profileForm input:focus {
  outline: none;
}

.profileForm button {
  width: 215px;
  padding: 0.6em 0;
  background-color: rgba(54, 9, 121, 0.9752275910364145);
  border: rgba(54, 9, 121, 0.9752275910364145);
  border-radius: 3px;
  color: rgba(212, 187, 250, 0.975);
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 0.2em;
  font-size: 0.6em;
  font-weight: bold;
}

.profileForm button:hover {
  background-color: rgba(62, 22, 122, 0.975);
}

.profileForm ul {
  background-color: #360979a8;
  border-radius: 5px;
  border: 1px solid #ceaefff9;
  padding: 0.5em 2em;
  font-size: 0.8em;
  font-weight: normal;
}

.profileForm ul > li {
  color: #ceaefff9;
  padding: 0.5em;
}

.profileForm strong {
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: underline;
  margin-right: 0.5em;
  font-size: 0.9em;
  letter-spacing: 1px;
}

.group-upload {
  margin-bottom: 1em;
}

.group-upload input {
  display: none;
}

.group-upload label {
  cursor: pointer;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .profileForm {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 5em;
  }
  .profileForm .group {
    display: flex;
    padding: 1em 1em;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .profileForm label {
    width: 100%;
    font-size: 0.6em;
    margin: 0;
  }
  .profileForm input {
    width: 100%;
  }
  .profileForm .group {
    width: 100%;
  }
  .profileForm ul {
    width: 100%;
    font-size: 10pt;
  }
  .profileForm ul {
    width: 100%;
    font-size: 0.7em;
  }
  .profileForm ul {
    line-height: 1.5em;
  }
  .profileForm ul > li {
    padding: 0.3em;
  }
}

@media (min-width: 1440px) {
  .profileForm {
    padding: 0 12em;
  }
}

.avatar {
  width: 150px;
  height: 150px;
  margin: 25px auto;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
}

.avatar img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

