.giftter-Fav {
  background: rgba(54, 9, 121, 0.7);
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  transition: all 0.3s ease;
}

.giftter-Fav:hover {
  background: rgb(44, 8, 99);
}

.giftter-Fav .icon {
  width: 30px;
  color: rgb(224, 224, 224);
}
