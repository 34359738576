.loginForm {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loginForm .group {
  display: flex;
  padding: 1em;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.loginForm label {
  color: #ceaefff9;
  text-transform: uppercase;
  font-size: 0.6em;
  letter-spacing: 0.1em;
  margin-bottom: 1em;
}

.loginForm input {
  padding: 0.5em 2.5em 0.5em 1em;
  color: #000000;
  background-color: #bdbdbd;
  border: #bdbdbd;
  border-radius: 5px;
}

.loginForm input:focus {
  outline: none;
}

.loginForm button {
  width: 215px;
  padding: 0.6em 0;
  background-color: rgba(54, 9, 121, 0.9752275910364145);
  border: rgba(54, 9, 121, 0.9752275910364145);
  border-radius: 3px;
  color: rgba(212, 187, 250, 0.975);
  text-transform: uppercase;
  cursor: pointer;
  letter-spacing: 0.2em;
  font-size: 0.6em;
  font-weight: bold;
}

.loginForm button:hover {
  background-color: rgba(62, 22, 122, 0.975);
}

.loginForm ul {
  background-color: #360979a8;
  border-radius: 5px;
  border: 1px solid #ceaefff9;
  padding: 0.5em 2em;
  font-size: 0.8em;
  font-weight: normal;
}

.loginForm ul > li {
  color: #ceaefff9;
  padding: 0.5em;
}

.loginForm strong {
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: underline;
  margin-right: 0.5em;
  font-size: 0.9em;
  letter-spacing: 1px;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .loginForm {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 5em;
  }
  .loginForm .group {
    display: flex;
    padding: 1em 1em;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .loginForm label {
    width: 100%;
    font-size: 0.6em;
    margin: 0;
  }
  .loginForm input {
    width: 100%;
  }
  .loginForm .group {
    width: 100%;
  }
  .loginForm ul {
    width: 100%;
    font-size: 0.7em;
  }
  .loginForm ul {
    line-height: 1.5em;
  }
  .loginForm ul > li {
    padding: 0.3em;
  }
}

@media (min-width: 1440px) {
  .loginForm {
    padding: 0 12em;
  }
}
