@import "https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;700&display=swap";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Josefin Sans", sans-serif;
  overflow-x: hidden;
  min-width: 375px;
  min-height: 100vh;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 1) 0%,
    rgba(54, 9, 121, 0.9752275910364145) 50%,
    rgba(0, 0, 0, 1) 100%
  );
  font-size: 18px;
}

.container {
  background-color: #000000a8;
  border-radius: 10px;
  border: 2px solid #250653c7;
  width: 80%;
  margin: 1em auto;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
}

h3 {
  color: #ceaefff9;
}

hr {
  margin: 0.5em auto;
  width: 100%;
  display: block;
  border-color: #360979f9;
  border-radius: 1px;
  border-style: solid;
}

.error {
  color: #ceaefff9;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 25pt;
}

/* The emerging W3C standard
   that is currently Firefox-only */
* {
  scrollbar-width: thin;
  scrollbar-color: black #360979f9;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar-track {
  background: black;
}
*::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 2px;
  border: 1px solid #360979f9;
}
