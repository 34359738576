.giftter {
  --gifShadow-size: var(--gridList-gap, 6px);
  margin-bottom: 0.5em;
  position: relative;
}

.giftter-buttons {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}

.giftter-link {
  color: #000;
  display: block;
  text-decoration: none;
  position: relative;
  width: 100%;
}

.giftter h4 {
  background: rgba(0, 0, 0, 0.3);
  bottom: 0;
  font-size: 0.7em;
  color: #d9bfff;
  margin: 0;
  position: absolute;
  padding: 0.5em 0.5em;
  width: 100%;
  line-height: 1.5em;
}

.giftter img {
  width: 100%;
  object-fit: contain;
  vertical-align: top;
}

i {
  font-size: 80px;
}

.giftter {
  margin-bottom: 0;
}

.giftter,
.giftter-link {
  height: 100%;
}
.giftter-link img {
  height: inherit;
  object-fit: cover;
}

.giftter:nth-child(5n + 1) img {
  background-color: var(--brand-color_1);
}
.giftter:nth-child(5n + 2) img {
  background-color: var(--brand-color_2);
}
.Gif:nth-child(5n + 3) img {
  background-color: var(--brand-color_3);
}
.giftter:nth-child(5n + 4) img {
  background-color: var(--brand-color_4);
}
.giftter:nth-child(5n + 5) img {
  background-color: var(--brand-color_5);
}

.giftter:hover:nth-child(5n + 1) {
  box-shadow: 0 0 0 var(--gifShadow-size) var(--brand-color_1);
}
.giftter:hover:nth-child(5n + 2) {
  box-shadow: 0 0 0 var(--gifShadow-size) var(--brand-color_2);
}
.giftter:hover:nth-child(5n + 3) {
  box-shadow: 0 0 0 var(--gifShadow-size) var(--brand-color_3);
}
.giftter:hover:nth-child(5n + 4) {
  box-shadow: 0 0 0 var(--gifShadow-size) var(--brand-color_4);
}
.giftter:hover:nth-child(5n + 5) {
  box-shadow: 0 0 0 var(--gifShadow-size) var(--brand-color_5);
}

.giftter:hover:nth-child(5n + 1) h4 {
  background-color: var(--brand-color_1);
  color: var(--theme-body-bg);
}
.giftter:hover:nth-child(5n + 2) h4 {
  background-color: var(--brand-color_2);
  color: var(--theme-body-bg);
}
.giftter:hover:nth-child(5n + 3) h4 {
  background-color: var(--brand-color_3);
  color: white;
}
.giftter:hover:nth-child(5n + 4) h4 {
  background-color: var(--brand-color_4);
  color: white;
}
.giftter:hover:nth-child(5n + 5) h4 {
  background-color: var(--brand-color_5);
  color: var(--theme-body-bg);
}

.giftter:nth-child(11n + 1) {
  grid-column: span 2;
  grid-row: span 2;
}

.giftter:nth-child(8n + 1) {
  grid-column-end: span 2;
  grid-row-end: span 2;
}

.giftter:nth-child(10n + 3) {
  grid-column: span 2;
  grid-row: span 1;
}

@media screen and (max-width: 45rem) {
  .giftter:nth-child(11n + 1),
  .giftter:nth-child(8n + 1),
  .giftter:nth-child(10n + 3) {
    grid-column: span 1;
    grid-row: span 1;
  }
}
