.search {
  display: flex;
  margin: auto;
  width: 90%;
}

.searchTerm {
  width: 100%;
  padding: 5px 5px 5px 10px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: #000000;
  background-color: #bdbdbd;
  border: 1px solid #bdbdbd;
  border-right: none;
  font-size: 0.7em;
}

.searchButton {
  width: 40px;
  text-align: center;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  /* font-size: 20px; */
  background-color: rgba(54, 9, 121, 0.9752275910364145);
  border: 1px solid rgba(54, 9, 121, 0.9752275910364145);
  color: rgba(212, 187, 250, 0.975);
}

/*Resize the wrap to see the search bar change!*/
.searchBar {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  min-height: 5vh;
  background-color: #000000;
  padding: 0 1em;
  padding-bottom: 1em;
  width: 100%;
}

.search input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg)
    no-repeat 50% 50%;
  background-size: contain;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
}

.search input[type="search"]:focus::-webkit-search-cancel-button {
  opacity: 0.3;
  pointer-events: all;
}

.search input[type="search"].dark::-webkit-search-cancel-button {
  filter: invert(1);
}

.user-profile {
  display: none;
}

@media screen and (min-width: 786px) {
  .searchBar {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    padding: 0 3em;
    padding-bottom: 1em;
    width: 100%;
  }

  .search {
    margin: auto;
    width: 50%;
  }

  .user-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: auto;
  }

  .avatar-mini {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .avatar-mini img {
    display: block;
    width: 100%;
  }

  .username {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    letter-spacing: 2px;
    text-align: center;
    margin-left: 1em;
    font-size: 0.8em;
  }
}
