.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  padding: 1em 3em;
  min-height: 8vh;
  flex-wrap: wrap;
}

.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
}

.logo h1 {
  color: #ceaefff9;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 1.1em;
  text-align: left;
}

.logo a {
  text-decoration: none;
  color: #ceaefff9;
  width: 100%;
}

.burger {
  cursor: pointer;
  width: 20px;
  height: 20px;
  color: #ceaefff9;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.toggle {
  animation: burgerChange 0.3s ease;
}

.nav-links {
  display: none;
  opacity: 0;
  animation: navLinkFade 0.3s ease;
}

.nav-active {
  display: block;
  opacity: 1;
  padding-top: 1em;
  position: absolute;
  width: 100%;
  height: auto;
  background: rgba(0, 0, 0, 0.97);
  z-index: 10;
  border-top: 1px solid #360979f9;
  top: 3em;
}

.nav-links li {
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 1.5em 0;
  width: 80%;
  margin: auto;
  border-bottom: 1px solid #360979f9;
}

.nav-links li:last-child {
  border: none;
}

.nav-links a {
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 3px;
  font-weight: bold;
  font-size: 0.7em;
  color: #9b5bfbf9;
}

.nav-links a:hover {
  color: rgba(62, 22, 122, 0.975);
}

.nav-links a.active {
  color: #ceaefff9;
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes burgerChange {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(180deg);
  }
}

@media screen and (min-width: 768px) {
  body {
    overflow-x: hidden;
  }
  .burger {
    display: none;
  }
  .nav {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    padding: 1em 3em;
    min-height: 8vh;
  }
  .nav-links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    opacity: 1;
    animation: none;
  }

  .nav-active {
    background: rgba(0, 0, 0, 0.97);
    z-index: 1;
    border-top: none;
    top: 0;
  }
  .logo {
    width: 20%;
    justify-content: flex-start;
  }
  .nav-links li {
    list-style: none;
    width: 80%;
    border-bottom: none;
    margin: 0 1em;
  }
}
